/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  /* font: inherit; */
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: DM Sans, sans-serif;
  overflow-x: hidden !important;
  overflow-y: overlay;
  color: #090a18;
}

a {
  text-decoration: none;
}

button {
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  user-select: none;
  font-family: DM Sans, sans-serif;
}

body::-webkit-scrollbar {
  -webkit-appearance: none;
  appearance: none;
  width: 7px;
}

body::-webkit-scrollbar-track {
  appearance: none;
  background-color: transparent;
}

body::-webkit-scrollbar-thumb {
  -webkit-appearance: none;
  appearance: none;
  background-color: rgba(0, 0, 0, 0.3);
  transition: 0.3s;
  border-radius: 5px;
}

input {
  border: none;
  outline: none;
  background-color: transparent;
}

/* input[type="date"] {
  color: transparent;
} */

input[type="date"]:focus {
  color: #090a18;
}

a {
  color: #090a18;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.flexRow {
  display: flex;
  flex-direction: row;
}

.justifySpaceBetween {
  justify-content: space-between;
}

.MuiList-padding {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.MuiPickersDatePickerRoot-toolbar.MuiPickersToolbar-toolbar {
  background-color: #31b4b9;
}
.MuiButtonBase-root.MuiIconButton-root.MuiPickersDay-day.MuiPickersDay-daySelected {
  background-color: #31b4b9;
}
/* .MuiFormControl-root.hsZZkk {
  flex-direction: row !important;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
}
.MuiInput-underline:before {
  border-bottom: inherit !important;
} */
